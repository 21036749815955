import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import styles from './index.css'

const Button = forwardRef(({ children, size, className, isLoading, ...props }, ref) => {
  let classNames = className
  if (!classNames) {
    classNames = size === 'normal' ? styles.base : styles[size]
  }

  if (isLoading) {
    classNames += ` ${styles._loading}`
    props.disabled = isLoading
  }

  return (
    <button ref={ref} className={classNames} {...props}>
      {children}
    </button>
  )
})

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small', 'mini', 'tiny', 'icon', 'link']),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}

Button.defaultProps = {
  type: 'button',
  size: 'normal'
}

export default Button

import { Suspense as ReactSuspense } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'

const IntersectionSuspense = ({ fallback, rootMargin, children }) => {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin })

  return (
    <div ref={ref}>{inView && <ReactSuspense {...{ fallback }}>{children}</ReactSuspense>}</div>
  )
}

IntersectionSuspense.propTypes = {
  children: PropTypes.any.isRequired,
  rootMargin: PropTypes.string,
  fallback: PropTypes.any
}

export default IntersectionSuspense

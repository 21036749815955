/* global process */

import { createElement, lazy, useCallback, useEffect, useState } from 'react'
import { camelizeKeys } from 'humps'

import Suspense from 'components/lib/suspense'

const pageLoader = document.querySelector('#pageLoader')
const isDev = process.env.NODE_ENV === 'development'

// Captures clicks on component links (`A` tags with href starting with `/components/`) and handles
// component loading. Allowing you to load, and mount a component on demand.
const LinkComponentManager = () => {
  const [components, setComponents] = useState([])
  const clickListener = useCallback(event => {
    let ele = event.target

    if (ele.nodeName !== 'A') {
      ele = ele.closest('a')
    }

    if (ele?.nodeName === 'A') {
      const href = ele.attributes.href?.value

      // Bail if link is not a component loader.
      if (!href || !href.startsWith('/components/')) return

      event.preventDefault()

      let componentPath = href.replace(/^\/components\//, '')
      if (href.endsWith('/component')) {
        componentPath = componentPath.replace(/\/component$/, '')
      }

      setComponents(state => {
        if (state.some(c => c.componentPath === componentPath && c.element === ele)) return state

        const props = camelizeKeys(ele.dataset.props ? JSON.parse(ele.dataset.props) : {})

        if (isDev) {
          console.groupCollapsed(
            '[REACT:link] Mounted on click %o at %o',
            componentPath,
            stringifyEl(ele)
          )
          console.log({ props, element: ele })
          console.groupEnd()
        }

        return state.concat([
          {
            component: lazy(() => import(`components/${componentPath}/index.entry.jsx`)),
            element: ele,
            componentPath,
            props
          }
        ])
      })
    }
  }, [])

  // Unmount the component after it's closed.
  const onUnmount = useCallback((componentPath, element) => {
    isDev && console.debug('[REACT:link] Unmounted %o at %o', componentPath, stringifyEl(element))

    setComponents(s => s.filter(c => c.componentPath !== componentPath && c.element !== element))
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', clickListener)

    return () => {
      document.body.removeEventListener('click', clickListener)
    }
  }, [clickListener])

  return (
    <Suspense fallback={<SuspenseFallback />}>
      {components.map(({ component, element, componentPath, props }, i) =>
        createElement(component, {
          ...props,
          element,
          key: i,
          onUnmount: () => onUnmount(componentPath, element)
        })
      )}
    </Suspense>
  )
}

const SuspenseFallback = () => {
  useEffect(() => {
    pageLoader.style.display = 'block'
    return () => void (pageLoader.style.display = 'none')
  }, [])

  return <></>
}

function stringifyEl(el, truncate = 50) {
  const outerHTML = el.outerHTML
  let ret = outerHTML
  ret = ret.slice(0, Math.max(0, truncate))

  // If we've truncated, add an elipsis.
  if (outerHTML.length > truncate) {
    ret += '...'
  }

  return ret
}

export default LinkComponentManager

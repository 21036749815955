/* global process */

import { createElement, useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import useMatchMedia from 'assets/lib/hooks/use_match_media'
import Suspense from 'components/lib/suspense'
import Fallback from './fallback'

const isDev = process.env.NODE_ENV === 'development'

const EntryComponent = ({ domElement, componentPath, ...comp }) => {
  const matchedMedia = useMatchMedia(comp.props.onlyDevice)
  const contentLoader =
    (comp.contentLoader || domElement.hasChildNodes()) && domElement.firstElementChild

  useEffect(() => {
    if (isDev && (comp.props.onlyDevice ? matchedMedia : true)) {
      console.groupCollapsed('[REACT] Mounted %o', componentPath)
      console.log(comp)
      console.groupEnd()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Do not render if `onlyDevice` prop defined and is not matched.
  if (comp.props.onlyDevice && !matchedMedia) return null

  return createPortal(
    <Suspense fallback={<Fallback contentLoader={contentLoader} />}>
      {createElement(comp.component, comp.props)}
    </Suspense>,
    domElement
  )
}

EntryComponent.propTypes = {
  componentPath: PropTypes.any,
  domElement: PropTypes.any
}

export default EntryComponent

/* eslint-disable react/prop-types */

import { useEffect, useMemo, useState } from 'react'

import EntryComponent from './entry_component'

const ObservedComponent = ({ domElement, componentPath, ...comp }) => {
  const [isVisible, setIsVisible] = useState(false)
  const observer = useMemo(() => {
    return new IntersectionObserver(entries => {
      entries.forEach(entry => {
        !isVisible && entry.isIntersecting && setIsVisible(true)
      })
    })
  }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      observer.unobserve(domElement)
      return
    }

    observer.observe(domElement)

    return () => observer.unobserve(domElement)
  }, [domElement, isVisible, observer])

  if (!isVisible) return null

  return <EntryComponent {...{ domElement, componentPath }} {...comp} />
}

export default ObservedComponent

import { createContext, Suspense as ReactSuspense, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import IntersectionSuspense from './intersection.jsx'

const SuspenseContext = createContext()

export function useSuspended() {
  return useContext(SuspenseContext)
}

const SmartSuspense = ({
  fallback,
  children,
  rootMargin,
  renderOnIntersection,
  renderEachOnIntersection
}) => {
  // See https://github.com/facebook/react/issues/14536#issuecomment-602138550
  const [suspended, setSuspended] = useState(true)
  const Fallback = props => {
    useEffect(() => {
      setSuspended(true)
      return () => setSuspended(false)
    }, [])

    return props.children
  }

  if (renderEachOnIntersection && Array.isArray(children)) {
    return (
      <>
        {children.map((child, i) => (
          <IntersectionSuspense
            key={i}
            fallback={<Fallback>{fallback}</Fallback>}
            {...{ rootMargin }}
          >
            {child}
          </IntersectionSuspense>
        ))}
      </>
    )
  }

  if (renderOnIntersection) {
    return (
      <IntersectionSuspense fallback={<Fallback>{fallback}</Fallback>} {...{ rootMargin }}>
        {children}
      </IntersectionSuspense>
    )
  }

  return (
    <ReactSuspense fallback={<Fallback>{fallback}</Fallback>}>
      <SuspenseContext.Provider value={suspended}>{children}</SuspenseContext.Provider>
    </ReactSuspense>
  )
}

SmartSuspense.propTypes = {
  children: PropTypes.any.isRequired,

  // If true, will render ALL child components when they appear in the browsers viewport.
  renderOnIntersection: PropTypes.bool,

  // If true, will render EACH child component when they appear in the browsers viewport.
  renderEachOnIntersection: PropTypes.bool,

  rootMargin: PropTypes.string,
  fallback: PropTypes.any
}

SmartSuspense.defaultProps = {
  renderOnIntersection: false,
  renderEachOnIntersection: false,
  rootMargin: '100px',
  fallback: <span></span>
}

export default SmartSuspense

/* eslint-disable react/prop-types */

import { useEffect } from 'react'

const Fallback = ({ contentLoader }) => {
  useEffect(() => {
    contentLoader && contentLoader.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!contentLoader) return null

  return (
    <div style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: contentLoader.outerHTML }} />
  )
}

export default Fallback

import { deepEqual } from 'fast-equals'
import humps from 'humps'

export const createUseFetch = fetch => {
  // Create a set of caches for this hook.
  const caches = []

  function useFetch(input, init, options = {}) {
    const { metadata = false, lifespan = 0 } = options

    // Check each cache by this useFetch hook.
    for (const cache of caches) {
      // If this cache matches the request,
      if (deepEqual(input, cache.input) && deepEqual(init, cache.init)) {
        // If an error occurred, throw it so that componentDidCatch can handle it.
        if (Object.prototype.hasOwnProperty.call(cache, 'error')) {
          throw cache.error
        }

        // If a response was successful, return it.
        if (Object.prototype.hasOwnProperty.call(cache, 'response')) {
          if (metadata) {
            return {
              bodyUsed: cache.bodyUsed,
              contentType: cache.contentType,
              headers: cache.headers,
              ok: cache.ok,
              redirected: cache.redirected,
              response: cache.response,
              status: cache.status,
              statusText: cache.statusText,
              url: cache.url
            }
          }

          return cache.response
        }

        // If we are still waiting, throw the Promise so that Suspense can fallback.
        throw cache.fetch
      }
    }

    // If no request in the cache matched this one, create a new cache entry.
    const cache = {
      // Make the fetch request.
      fetch: fetch(input, init)
        .then(response => {
          if (response instanceof Response === false) return response

          cache.contentType = response.headers.get('Content-Type')

          if (metadata) {
            cache.bodyUsed = response.bodyUsed
            cache.headers = response.headers
            cache.ok = response.ok
            cache.redirected = response.redirected
            cache.status = response.status
            cache.statusText = response.statusText
          }

          if (cache.contentType && cache.contentType.includes('application/json')) {
            if (response.status === 204) return ''

            return response.json().then(data => humps.camelizeKeys(data))
          }

          return response.text()
        })

        // Cache the response.
        .then(response => {
          cache.response = response
        })

        // Handle an error.
        .catch(error => {
          cache.error = error
        })

        // Invalidate the cache.
        .then(() => {
          if (lifespan > 0) {
            setTimeout(() => {
              const index = caches.indexOf(cache)
              index !== -1 && caches.splice(index, 1)
            }, lifespan)
          }
        }),
      init,
      input
    }

    caches.push(cache)

    throw cache.fetch
  }

  return useFetch
}

export default createUseFetch()

import { useMemo } from 'react'

const breakpoints = {
  phone: 320,
  largephone: 425,
  tablet: 768,
  desktop: 1024,
  largedesktop: 1440
}

const DEVICES = {
  mobile: `(max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`
}

export default (...devices) =>
  useMemo(() => {
    return devices.some(device => window.matchMedia(DEVICES[device]).matches)
  }, [devices])

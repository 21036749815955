/* global process */

import { lazy, useCallback, useEffect, useRef, useState } from 'react'
import history from 'history/hash'

import Sheet from 'components/lib/sheet'
import { useSheet } from 'components/lib/sheet/hooks'

const isDev = process.env.NODE_ENV === 'development'

function debug(msg, ...params) {
  if (!isDev) return

  console.groupCollapsed(`[ROUTE] ${msg}`, ...params.slice(0, -1))
  console.info(params.at(-1))
  console.groupEnd()
}

// Handle route (hash page history) components.
//
// Components that react to the page's hash history (eg. "#/edit"), usually to load when the page
// history changes. Each component is loaded within a Sheet.
const RouteManager = () => {
  const previousPathRef = useRef(history.location.pathname)
  const [components, setComponents] = useState([])

  const addComponent = useCallback(
    name => {
      !components.includes(name) && setComponents([...components, name])
    },
    [components]
  )

  const onExit = useCallback(
    component => {
      const route = window.reactComponentRoutes?.[history.location.pathname]

      // If we still have a matching route, the sheet will have been manually closed, so change the
      // history here.
      route && history.push({ pathname: '/' })

      // Remove the component to allow it to be unmounted.
      components.includes(component) && setComponents(components.filter(x => x !== component))
    },
    [components]
  )

  // We only manage the components array here. The opening/closing of each component is handled by
  // the Sheeted component below.
  useEffect(() => {
    const route = window.reactComponentRoutes?.[history.location.pathname]

    // Handle initial page.
    if (route && history.location.pathname !== '/') {
      debug('%o (initial)', history.location.pathname, { route })

      addComponent(route)
    }

    // Listen for history changes.
    return history.listen(({ location }) => {
      const route = window.reactComponentRoutes?.[location.pathname]

      if (route && previousPathRef.current !== location.pathname) {
        debug('%o to %o', previousPathRef.current, location.pathname, { route })

        addComponent(route)
      }

      // Update previous path
      previousPathRef.current = location.pathname
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return components.map((component, i) => <Sheeted key={i} component={component} onExit={onExit} />)
}

// eslint-disable-next-line react/prop-types
const Sheeted = ({ component, onExit }) => {
  const { id, toggleSheet } = useSheet(component)
  const Component = lazy(() => import(`components/${component}/index.entry.jsx`))

  useEffect(() => {
    // The sheet requires its own listener, so it can open/close the sheet correctly.
    return history.listen(({ location }) => {
      const route = window.reactComponentRoutes?.[location.pathname]

      debug('Sheet %o', location.pathname, { route })
      toggleSheet(route !== undefined)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Sheet id={id} isOpen onExit={() => onExit(component)}>
      <Component />
    </Sheet>
  )
}

export default RouteManager

import { setExtra } from '@sentry/react'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { store } from 'ibiza'
import { isEmpty, isPlainObject, set } from 'lodash'

import { createUseFetch } from '../hooks/use_fetch.js'

const responseTypes = {
  json: 'application/json',
  text: 'text/*',
  formData: 'multipart/form-data',
  arrayBuffer: '*/*',
  blob: '*/*'
}

const requestMethods = new Set(['get', 'post', 'put', 'patch', 'head', 'delete'])

export class FetchError extends Error {
  constructor(request, requestOptions, response = null, options = {}) {
    super(response?.statusText || options.cause?.message || response?.status, { ...options })

    this.name = 'FetchError'

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else if (response) {
      this.stack = new Error(response.statusText).stack
    }

    const requestHeaders = {}
    for (let pair of request.headers.entries()) {
      requestHeaders[pair[0]] = pair[1]
    }

    setExtra('fetchRequest', {
      url: request.url,
      method: requestOptions.method || request.method,
      searchParams: requestOptions.searchParams,
      body: requestOptions.bodyParams,
      requestHeaders
    })

    this.response = response
    this.request = request
    this.isCriticalError = false
    this.isServerError = false // A server error is one with an HTTP status of 5**.
    this.isClientError = false // A client error is one with an HTTP status of 4**.
    this.isHandled = false // Has the error been handled with a presented error message.

    if (response) {
      const ignoreHeaders = new Set(['server-timing'])
      const responseHeaders = {}
      for (let pair of response.headers.entries()) {
        if (ignoreHeaders.has(pair[0])) continue

        responseHeaders[pair[0]] = pair[1]
      }

      setExtra('fetchResponse', {
        ok: response.ok,
        redirected: response.redirected,
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        responseHeaders
      })

      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401 || response.status === 429) {
          // Unauthorised or rate limited, so force user to reload and login again.
          this.isCriticalError = true
        }

        this.isClientError = true
      } else if (response.status >= 500) {
        this.isServerError = true
      }
    } else {
      this.isCriticalError = true
    }
  }
}

// Wraps the browser's `fetch()` with a few bells and whistles:
// - Sets `credentials` to 'same-origin`.
// - Sets `'X-Requested-With': 'XMLHttpRequest'` header for Rails compat.
// - Throws a `FetchError` if the response is not `ok`.
//
// Returns a Response object with Body methods added for convenience. So you can, for example, call
// fetch(input).json() directly without having to await the Response first. When called like that,
// an appropriate Accept header will be set depending on the body method used. Unlike the Body
// methods of window.Fetch; these will throw an FetchError if the response status is not in the
// range of 200...299. Also, .json() will return an empty string if the response status is 204
// instead of throwing a parse error due to an empty body.
//
// It has an almost identical API signature, with these additional options as part of the `init`
// argument:
//
// - handleClientError?: Boolean - When true, will present a pretty error message to the user upon a
//   client error. (default: true)
// - handleServerError?: Boolean - When true, will present a pretty error message to the user upon a
//   server error. (default: true)
// - handleError?: Boolean - When true, will present a pretty error message to the user upon any
//   error. (default: true)
// - isCritical?: Boolean - If true, will present any error as a critical one, resulting in an
//   undismissable alert. (default: false)
// - searchParams?: Object - Search parameters to include in the request URL. Setting this will
//   override all existing search parameters in the `resource`.
// - buildRequest?: Function - A function that will be called with the `url` and `options`, and
//   should return a `Request` object.
const htFetch = (resource, init) => {
  const options = {
    // Custom options
    handleClientError: true,
    handleServerError: true,
    handleError: true,
    searchParams: null,
    isCritical: false,

    // Fetch options (defaults)
    credentials: 'same-origin',

    // Ensure all works well with browser history, ie. avoiding incorrect cached fetches when
    // clicking back.
    cache: 'no-cache',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': '',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    ...init
  }

  let { searchParams, method, buildRequest } = options
  const { handleError, handleClientError, handleServerError, isCritical } = options

  if (typeof document !== 'undefined') {
    let token = document.getElementsByName('csrf-token')[0]
    if (token) options.headers['X-CSRF-Token'] = token.getAttribute('content')

    // Fetch the therapist/client id from the meta tag, and assign as a header. This allows global
    // access to the current user.
    let therapistId = document.getElementsByName('htp:therapist_id')[0]
    if (therapistId) options.headers['X-Therapist-Id'] = therapistId.getAttribute('content')

    let clientId = document.getElementsByName('htp:client_id')[0]
    if (clientId) options.headers['X-Client-Id'] = clientId.getAttribute('content')
  }

  // Make sure method value is uppercase to avoid casing issues on server.
  options.method = requestMethods.has(method) ? method.toUpperCase() : method

  let bodyParams = {}
  if (options.body) {
    let { body } = options

    if (!isPlainObject(body)) {
      if (body instanceof FormData) {
        for (const [name, value] of body.entries()) {
          body = set(body, name, value)
        }
      } else {
        for (const [name, value] of body) {
          body = set(body, name, value)
        }
      }
    }

    bodyParams = decamelizeKeys(body)

    // Convert keys to snake case (underscored).
    options.body = JSON.stringify(bodyParams)
  }

  const url = new URL(resource, location.origin)

  // Assign `searchParams` to the `resource`.
  if (searchParams) {
    // Convert param keys to snake case (underscored).
    searchParams = decamelizeKeys(searchParams)

    url.search = new URLSearchParams(searchParams)
  }

  resource = buildRequest ? buildRequest(url, options) : new Request(url)

  function initFetchError({ error, response }) {
    return new FetchError(resource.clone(), { ...options, bodyParams, searchParams }, response, {
      cause: error
    })
  }

  function buildFetchError(error, response) {
    if (!(error instanceof FetchError)) {
      error = initFetchError({ error, response })
    }

    if (handleError) {
      if (error.isClientError) {
        handleClientError && presentFailure(error, { isCritical })
      } else if (error.isServerError) {
        handleServerError && presentFailure(error, { isCritical })
      } else {
        presentFailure(error, { isCritical })
      }
    }

    return error
  }

  const result = fetch(resource, options)
    .catch(error => {
      throw buildFetchError(error)
    })
    .then(response => {
      if (!response.ok) {
        const error = initFetchError({ response: response.clone() })

        if (error.isClientError) {
          const contentType = response.headers.get('Content-Type')
          if (contentType?.includes('application/json')) {
            return response
              .json()
              .catch(() => {
                handleError && handleClientError && presentFailure(error, { isCritical })
                throw error
              })
              .then(data => {
                // TODO: The server will sometimes have already camelised the keys, but we currently
                // have no way of knowing that. So for now - and because we always want camelised
                // keys - we have to camelise them again here.
                error.responseData = camelizeKeys(data)
                handleError && handleClientError && presentFailure(error, { isCritical })
                throw error
              })
          }
        }

        throw buildFetchError(error)
      }

      return response
    })

  for (const [type, mimeType] of Object.entries(responseTypes)) {
    result[type] = async () => {
      resource.headers.set('accept', resource.headers.get('accept') || mimeType)
      // eslint-disable-next-line unicorn/no-await-expression-member
      const response = (await result).clone()

      if (type === 'json') {
        if (response.status === 204) return ''

        // TODO: The server will sometimes have already camelised the keys, but we currently have no
        // way of knowing that. So for now - and because we always want camelised keys - we have to
        // camelise them again here.
        try {
          const json = await response.json()
          return camelizeKeys(json)
        } catch (error) {
          throw buildFetchError(error, response)
        }
      }

      return response[type]()
    }
  }

  return result
}

export default htFetch

export const useFetch = createUseFetch(htFetch)

export const presentFailure = async (error, options = {}) => {
  let msg = []
  let title

  if (options.message) {
    msg = [options.message]
  } else if (error.isServerError) {
    msg.push(
      `There was a problem with this request (${error.response.statusText}).`,
      'Most likely this is a problem on our end and we are looking into it as we speak.'
    )
  } else if (error.isClientError) {
    const contentType = error.response.headers.get('Content-Type')
    if (contentType?.includes('application/json')) {
      const data = error.responseData

      if (data?.errors && !isEmpty(data.errors)) {
        if (!options.title) {
          title = 'Ooops!'
          msg.push(
            'We are unable to continue as there is something not quite right with what you',
            'told us. Please check and rectify the following and try again.</p><p>'
          )
        }

        const errors = Array.isArray(data.errors) ? data.errors[0] : data.errors
        if (errors.base) {
          msg.push(errors.base[0])
        } else {
          msg.push(errors[Object.keys(errors)[0]][0])
        }
      }
    }
  }

  if (msg.length === 0) {
    msg.push(
      'Apologies, but we have been unable to continue with this request.',
      'Most likely this is a problem on our end and we are looking into it as we speak.',
      '</p><p>Details: <code>',
      error.message,
      '</code>'
    )
  }

  store.state.fetchError = {
    error,
    title: title || options.title,
    htmlContent: `<p>${msg.join(' ')}</p>`,
    canClose: !(error.isCriticalError || options.isCritical),
    onExit: () => {
      delete store.state.fetchError
    }
  }

  error.isHandled = true

  return error
}

/* eslint-disable react/prop-types */

import { useCallback } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { captureException, withScope } from '@sentry/react'

import ErrorModal from './error_modal'

const ErrorBoundary = ({ children, onError, ...props }) => {
  const errorHandler = useCallback(
    (error, { componentStack }) => {
      // See https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/errorboundary.tsx
      withScope(() => {
        const errorBoundaryError = new Error(error.message)
        errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`
        errorBoundaryError.stack = componentStack
        error.cause = errorBoundaryError

        const eventId = captureException(error, { contexts: { react: { componentStack } } })
        onError?.(error, componentStack, eventId)
      })
    },
    [onError]
  )

  if (!props.fallback && !props.fallbackRender && !props.FallbackComponent) {
    props.fallbackRender = ErrorModal
  }

  return (
    <ReactErrorBoundary {...props} onError={errorHandler}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary

/* global process */

import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Intercom, LiveChatLoaderProvider, useChat } from 'react-live-chat-loader'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useIbiza } from 'ibiza'

import ErrorBoundary from 'components/lib/error_boundary'
import ErrorModal from 'components/lib/error_modal'
import EntryComponent from './entry_component'
import LinkComponentManager from './link_component_manager'
import ObservedComponent from './observed_component'
import RouteManager from './route_manager'

const enableIntercom =
  !location.hostname.startsWith('admin.') &&
  process.env.NODE_ENV !== 'development' &&
  process.env.INTERCOM_APP_ID !== ''

const ComponentManager = ({ components }) => {
  return (
    <ErrorBoundary>
      <FetchErrorModal />
      <Toaster
        toastOptions={{
          duration: 5000,
          success: {
            style: {
              fontSize: '13px',
              borderRadius: '4px',
              color: 'var(--green5)',
              backgroundColor: 'var(--green1)'
            }
          },
          error: {
            duration: 10_000,
            style: {
              fontSize: '13px',
              borderRadius: '4px',
              color: 'var(--red)',
              backgroundColor: 'var(--red0)'
            }
          }
        }}
      />

      <RouteManager />
      <LinkComponentManager />

      {components.map((comp, key) =>
        comp.renderImmediately ? (
          <EntryComponent key={key} {...comp} />
        ) : (
          <ObservedComponent key={key} {...comp} />
        )
      )}

      {enableIntercom && (
        <LiveChatLoaderProvider
          providerKey={process.env.INTERCOM_APP_ID}
          provider="intercom"
          beforeInit={window.beforeIntercomInit}
          onReady={window.onIntercomReady}
        >
          <IntercomWidget />
        </LiveChatLoaderProvider>
      )}
    </ErrorBoundary>
  )
}

const IntercomWidget = () => {
  const [state, loadChat] = useChat({ loadWhenIdle: true })

  useEffect(() => {
    if (window.intercomSettings?.hide_default_launcher || state !== 'complete') {
      loadChat({ open: false })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (window.intercomSettings?.hide_default_launcher || state === 'complete') return null

  return <Intercom color="var(--primary-color)" />
}

const FetchErrorModal = () => {
  const { fetchError } = useIbiza()

  return fetchError ? <ErrorModal {...fetchError} /> : null
}

ComponentManager.propTypes = exact({
  components: PropTypes.array.isRequired
})

export default ComponentManager

/* eslint-disable react/prop-types */

import isChunkLoadError from 'assets/lib/utils/is_chunk_load_error'
import { useModal } from 'components/lib/modal/hooks'
import Button from './html_elements/button/index'
import Modal from './modal'

import modalFooterStyles from './modal/footer.css'

const innerStyle = { padding: '10px 25px', fontSize: 16 + 'px' }
const DEFAULT_TITLE = "Something's not quite right!"
const DEFAULT_CONTENT =
  "We're very sorry, but we appear to have encountered an error that means we cannot continue " +
  'with your request. Our team has been notified. Please reload the page and try again.'

const ErrorModal = ({ title = DEFAULT_TITLE, content = DEFAULT_CONTENT, ...props }) => {
  if (isChunkLoadError(props.error)) {
    title = 'Update Required'
    content = "We've recently updated this page. Please reload, and try your request again."
  }

  return (
    <Modal
      id="errorBoundaryFallback"
      isOpen
      onExit={props.onExit}
      canClose={props.canClose}
      header={<h2 style={{ paddingLeft: '15px', fontSize: '18px', fontWeight: 500 }}>{title}</h2>}
      footer={<Footer canClose={props.canClose} />}
    >
      {props.htmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} style={innerStyle} />
      ) : (
        <div style={innerStyle}>
          <p>{content}</p>
        </div>
      )}
    </Modal>
  )
}

const Footer = ({ canClose }) => {
  const { toggleModal } = useModal()

  const onClick = () => {
    canClose ? toggleModal(false) : location.reload()
  }

  return (
    <div className={modalFooterStyles.base}>
      <Button onClick={onClick} className={modalFooterStyles.secondaryButton}>
        {canClose ? <>OK</> : <>Reload &amp; Try Again</>}
      </Button>
    </div>
  )
}

export default ErrorModal
